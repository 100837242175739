import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Alert } from "reactstrap"
import Newsletter from "../components/newsletter"

const ContactSuccessPage = () =>     
  <Layout>
    <SEO title="Contact" />

    <section>
      <Container className="py-3 py-xl-5">
        <Row className="align-items-end">
          <Col xl="4">
            <h2 className="display-4 text-primary">Contact.</h2>
            <p className="lead mt-4">
              Do you have questions? Do not hesitate to contact us for further details.
            </p>
          </Col>

          <Col sm="4">
            <Alert color="success">
              <h4 className="alert-heading">Thank you for your request</h4>
              We will handle your request and contact you 
              as soon as possible.
            </Alert>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="bg-light">
      <Container className="py-2 py-xl-5">
        <Row className="my-5">
          <Col xl="4">
            <h2 className="display-4">Imprint.</h2>
            <p className="lead my-4">
              §5 E-Commerce Gesetz.
            </p>
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col md="6">
                <address className="lead">
                  FH OÖ F&amp;E GmbH<br />
                  JRC LIVE<br />
                  Franz-Fritsch-Straße 11/Top 3<br />
                  4600 Wels
                </address>
                
                <p className="lead">
                  FB Nr. 236733m<br />
                  UID Nr. ATU57300236
                </p>
              </Col>

              {/*<Col sm="4" md="3" lg="2">
                <a href="https://www.logistikum.at">
                  <Img fluid={data.logistikum.childImageSharp.fluid} className="" alt="Logistikum" />
                </a>
              </Col>*/}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>

    <Newsletter />
  </Layout>

export default ContactSuccessPage